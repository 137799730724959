'use strict';
import autoComplete from "@tarekraafat/autocomplete.js";


const autoCompleteJS = new autoComplete({
    placeHolder: "Search...",
    data: {
        src: async () => {
            try {
                const source = await fetch(`/search/autocomplete-data`);
                const data = await source.json();
                return data;
            } catch (error) {
                return error;
            }
        },
        keys: ['title'],
    },
    submit: true,
    resultItem: {
        highlight: true,
    },
    events: {
        input: {
            selection: (event) => {
                // TODO: ensure all channels are integrated in the autocomplete search results list
                const form = document.querySelector('#search-form');
                const selection = event.detail.selection.value.title;
                autoCompleteJS.input.value = selection;
                form.submit();
            }
        }
    }
});