'use strict';

//---------------------------------------------
// If we're on an Industry page (#product-results is present)
// OR we're on the Resources page (#resources-results is present)
// and the user has filtered their search results, load their
// page scroll to the product results area/resources results area
//---------------------------------------------
const productSearchResultsArea = document.getElementById('product-results');
const resourcesSearchResultsArea = document.getElementById('resource-results');

if (window.location.search) {
    if (productSearchResultsArea) {
        window.onload = function() {
            window.scrollTo({
                top: productSearchResultsArea.offsetTop - 100, 
                left: 0,
            })
        }
    } else if (resourcesSearchResultsArea) {
        window.onload = function() {
            window.scrollTo({
                top: resourcesSearchResultsArea.offsetTop - 100, 
                left: 0,
            })
        }
    }
}
