"use strict";

//---------------------------------------------
// Notice message cookie set/check
//---------------------------------------------
import Cookies from "js-cookie";
// console.log('test!');

if (!Cookies.get("notice-message")) {
  const noticeMessage = document.querySelector("#notice-message");
  const dismissNoticeBtn = noticeMessage?.querySelector("#dismiss-notice-message-btn");

  if (noticeMessage && dismissNoticeBtn) {

      noticeMessage.classList.add("open");
    
      const closeNoticeMessage = function () {
        noticeMessage.classList.add("close");
        noticeMessage.addEventListener("animationend", function () {
          noticeMessage.classList.remove("open");
          Cookies.set("notice-message", "set", { expires: 30 });
          noticeMessage.removeEventListener("animationend", closeNoticeMessage);
          noticeMessage.remove();
        }, {passive: true});
      };
    
      dismissNoticeBtn.addEventListener("click", closeNoticeMessage);
  }
}
