"use strict";

// https://ganlanyuan.github.io/tiny-slider/#usage
import { tns } from "tiny-slider";
import { TAILWIND_MD_BREAKPOINT, TAILWIND_LG_BREAKPOINT, TAILWIND_XL_BREAKPOINT } from "./config";

// TODO: Consider implementing debouncing for performance boost on resize:
// https://www.joshwcomeau.com/snippets/javascript/debounce/

// TODO: Refactor so that sliderOnOffToggle() function isn't contained
// within loop.. could bind to function? TBD
// Thought: https://github.com/ganlanyuan/tiny-slider/issues/675


// ***********************************************************************
// NOTE: Types of slideshows on site as of now:
// "product-hero"; "image_slideshow"; "linkable_image_slideshow"; 
// "related_products"; "featured_posts";


//---------------------------------------------
// ***** VARIABLES APPLICABLE FOR FEATURE SLIDESHOWS ONLY
//---------------------------------------------
// NOTE: The 'md' and 'lg' TailwindCSS breakpoints
const mdBreakpoint = TAILWIND_MD_BREAKPOINT;
const lgBreakpoint = TAILWIND_LG_BREAKPOINT;
const xlBreakpoint = TAILWIND_XL_BREAKPOINT;

// Sliders are uninit on load
let isInit = false;


//---------------------------------------------
// ***** FUNCTION APPLICABLE FOR FEATURE SLIDESHOWS ONLY
// Destroy/rebuild the slideshow functionality based on breakpoint
//---------------------------------------------
const sliderOnOffToggle = (slider) => {
  if(window.innerWidth >= lgBreakpoint && isInit == false) {
    slider = slider.rebuild();
    isInit = true;
  } else if(window.innerWidth <= (lgBreakpoint+1) && isInit == true) {
    slider.destroy();
    isInit = false;
  }
}

window.addEventListener("DOMContentLoaded", function () {
  const sliders = document.querySelectorAll(".tns-slider");

  if (sliders.length > 0) {

    // Init all slideshows on page
    sliders.forEach(function(slideshow) {
      // Product Hero Slideshows:
      // - never will be destroyed based on screen size; always active
      if (slideshow.dataset.slideshowType === "product-hero") {
        let slider = tns({
          container: slideshow,
          items: 1,
          slideBy: "page",
          nav: true,
          navPosition: "bottom",
          arrowKeys: true,
          controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          rewind: true,
        });
      } else if (slideshow.dataset.slideshowType === "featured_products") {     
        // For Featured Products bloqs
        // - never will be destroyed based on screen size; always active
        let slider = tns({
          container: slideshow,
          items: 1,
          slideBy: "page",
          nav: false,
          arrowKeys: true,
          controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          responsive: {
            [mdBreakpoint]: {
              items: 2,
            },
          },
        });
      } else if (slideshow.dataset.slideshowType == "image_slideshow") {
        // For Image Slideshow bloqs
        // - never will be destroyed based on screen size; always active
        let slider = tns({
          container: slideshow,
          items: 1,
          slideBy: "page",
          nav: false,
          arrowKeys: true,
          controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          responsive: {
            // We're using the lg breakpoint for Image slideshows (because of optional caption displaying
            // below the image is an option with these bloqs)
            [lgBreakpoint]: {
              items: 2,
            },
          },
        });
      } else if (slideshow.dataset.slideshowType == "linkable_image_slideshow") {
        // For Linkable Image Slideshow bloqs
        // - never will be destroyed based on screen size; always active
        let slider = tns({
          container: slideshow,
          items: 2,
          slideBy: "page",
          // center: true,
          rewind: true,
          nav: false,
          arrowKeys: true,
          controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          responsive: {
            [mdBreakpoint]: {
              items: 2,
            },
            [lgBreakpoint]: {
              items: 3,
            },
            [xlBreakpoint]: {
              items: 4,
            }
          },
        });
      } else if (slideshow.dataset.slideshowType == "related_products") {
        // For Related Products slideshows
        // - never will be destroyed based on screen size; always active
        let slider = tns({
          container: slideshow,
          items: 1,
          slideBy: "page",
          nav: false,
          arrowKeys: true,
          controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
          nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
          responsive: {
            [mdBreakpoint]: {
              items: 2,
            },
            [lgBreakpoint]: {
              items: 3,
            }
          },
        });
      } else if (slideshow.dataset.slideshowType === "featured_posts") {
        // Featured Post Slideshows:
        // - WILL BE DESTROYED based on screen size;
        //    at 'lgBreakpoint', should destroy/rebuild all slideshows
          let slider = tns({
            container: slideshow,
            items: 2,
            slideBy: "page",
            nav: false,
            arrowKeys: true,
            controlsContainer: `.controls[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`,
            prevButton: `.prevButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
            nextButton: `.nextButton[data-slider-id="${slideshow.getAttribute('data-slider-id')}"]`, 
            rewind: true,
            onInit: () => {
              isInit = true;
              // console.log(isInit);
            }
          });
          
          // check whether slideshows should be init or not based on screen size
          sliderOnOffToggle(slider);

          // Check to destroy/rebuild when window resized
          window.onresize = function() {
            sliderOnOffToggle(slider);
          };
      }
    });
  }

}, {passive: true});
