"use strict";

//---------------------------------------------
// Allows insert of a node after an existing node
//---------------------------------------------
export const insertAfter = function (newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}

//---------------------------------------------
// Wrapping an element in a parent
// Example usage: wrap(document.querySelector('div.wrap-me'), document.createElement('div'));
//---------------------------------------------
export const wrap = function (elementToWrap, wrapper) {
    elementToWrap.parentNode.insertBefore(wrapper, elementToWrap);
    wrapper.appendChild(elementToWrap);
}

//---------------------------------------------
// Need the baseURL for the template AJAX requests
//---------------------------------------------
export const getBaseUrl = function() {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port: '');
}

//---------------------------------------------
// Get URL segments
//---------------------------------------------
export const urlPathArray = window.location.pathname.split('/');