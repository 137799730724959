"use strict";

import { getBaseUrl } from "./utilities";

//---------------------------------------------
// FUTURE: Add smooth transition if content already loaded into the 
// results area, or nothing yet filtered
//---------------------------------------------


// Identify the country selector, state selector, results area to populate 
const countrySelector = document.querySelector('#country-dropdown');
const stateProvSelector = document.querySelector('#state-province-dropdown');
const repResults = document.querySelector('#rep-results');
const stateProvSelectorTemplateURL = '/_filters/find-a-rep-state-prov-filters';
const repDataTemplateURL = '/ajax/rep-results';

// let loadedRepResults = false;

const noOptionsHTML = `
    <div class="text-center grid leading-[30px] py-7">
        <div class="max-w-5xl mx-auto">
            <div class="text-lg">There are no options for your selected country.</div>
        </div>
    </div>
`;
const noRepsHTML = `
    <div class="text-center grid leading-[30px] py-7">
        <div class="max-w-5xl mx-auto">
            <div class="text-lg">There are no representatives for your selection.</div>
        </div>
    </div>
`;

if (countrySelector && stateProvSelector && repResults) {

//---------------------------------------------
// Reacts to either of the selectors having changed value
//---------------------------------------------
const listenForSelectorChanges = function() {
    // listen for change of the option of the country selector
    //  once received the states/provinces, load up the contents into the selector and reveal it
    countrySelector.addEventListener('change', function(e) {
        let selectedCountry = e.target.options[e.target.selectedIndex].dataset.catid;
        //  if it's a valid country, fetch all of the states/provinces to be populated 
        if (!selectedCountry) {
            toggleDisplay(stateProvSelector, false);
            return;
        }

        fetchURLData(stateProvSelectorTemplateURL, 'country', selectedCountry).then(options => {
            console.log(options);
            if (!options || options === null || options.trim() === '') {
                console.log('empty!');
                toggleDisplay(stateProvSelector, false);
                appendRepsToResults(noOptionsHTML);
                toggleDisplay(repResults, true);
                return;
            }
            repResults.innerHTML = '';
            console.log('NOT empty!');
            appendOptionsToStateProvSelector(options);
            toggleDisplay(stateProvSelector, true);
        });

    });

    //---------------------------------------------
    // listen for change of the option of the state selector
    //  if it's a valid state/prov, fetch all of the reps to show as results
    //  once received that data, load into the results section
    //---------------------------------------------
    stateProvSelector.addEventListener('change', function(e) {
        let selectedStateProv = e.target.options[e.target.selectedIndex].dataset.catid;
        //  if it's a valid country, fetch all of the states/provinces to be populated 
        if (!selectedStateProv) return;

        fetchURLData(repDataTemplateURL, 'state-prov', selectedStateProv).then(reps => {
            if (!reps || reps === null || reps.trim() === '') {
                appendRepsToResults(noRepsHTML);
                toggleDisplay(repResults, true);
                return;
            }
            repResults.innerHTML = '';
            appendRepsToResults(reps);
            toggleDisplay(repResults, true);

            // FUTURE: Denotes that we've already loaded some content into the rep results area for
            // sake of future transitioning
            // loadedRepResults = true;
        })
    });
}

//---------------------------------------------
// Changes whether the State/Province selector is visible on the page or not
//---------------------------------------------
const toggleDisplay = function(el, displayIt) {
    if (el.classList.contains('h-0') && displayIt) {
        el.classList.remove('h-0', 'opacity-0');
    } else if (!displayIt) {
        el.classList.add('h-0', 'opacity-0');
    }
}

//---------------------------------------------
// Fetches all of the HTML to populate the state-province selector based on the country selected
//---------------------------------------------
const fetchURLData = async function(templateURL, selectedOption, selectedCatId) {
    const response = await fetch(getBaseUrl() + `${templateURL}?${selectedOption}=${selectedCatId}`);
    const data = await response.text();
    return data;
}

//---------------------------------------------
// Append the options HTML to the state-province selector
//---------------------------------------------
const appendOptionsToStateProvSelector = function(options) {
    stateProvSelector.innerHTML = options;
}

//---------------------------------------------
// Append the reps HTML to the results section
//---------------------------------------------
const appendRepsToResults = function(reps) {
    repResults.innerHTML = reps;
}

//---------------------------------------------
// ************************************
// INITIALIZATION
//---------------------------------------------

    // if the dropdowns exist (so we're on the find a rep page), hide the state selector
    toggleDisplay(stateProvSelector);
    listenForSelectorChanges();

}