'use strict';

// See ignyte.dev2.thinkfullcircle.com nav scripts for clip path and reveal of text
// Also see csm.dev2.thinkfullcircle.com for more looping examples

import { prefersReducedMotionMediaQueryDetected } from "./config";

const textSectionsToReveal = ['[data-block-name="layered_images_with_copy_copy"] *', 
  '[data-block-name="image_with_copy_copy"] *', 
  '[data-block-name="copy_row"] :first-child', 
  '[data-block-name="video_feature_row_with_intro_copy"] [data-block-name="video_feature_row_copy"] *', 
  'section#locations-content', 
  'div.newly-wrapped-content'];
const textColumnSectionsToRevealWithStagger = document.querySelectorAll('[data-block-name="copy_column"]');
const homepageBody = document.querySelector('body.homepage');


//---------------------------------------------
// Specific elements to target delayed reveals for Homepage only
//---------------------------------------------
const doHomepageScrollReveals = function() {
  const heroBannerBloq = document.querySelector('[data-block-name="hero_banner_bloq"]');
  const heroBannerBloqIntro = heroBannerBloq.querySelector('.intro');
  const heroBannerBloqCopy = heroBannerBloq.querySelector('.copy');
  const heroBannerBloqIntroHeading = heroBannerBloqIntro.querySelector('h1');
  
  //---------------------------------------------
  // Transition reveal of h1 in Intro Section on Homepage
  //---------------------------------------------
  let tl = gsap.timeline({defaults: {ease: "power1.out", duration: 0.6}});
  
  if (heroBannerBloqIntroHeading && heroBannerBloqCopy) {

    tl.from(heroBannerBloqIntroHeading, {
      opacity: 0,
      y: '20%', // 20% of the height of the element
      delay: 0.6,
    }).from(heroBannerBloqCopy, {
        opacity: 0,
        y: '20%', // 20% of the height of the element
    });
  }
}

//---------------------------------------------
// Stagger slide up and opacity reveal of Copy Columns Row items
// NOTE: 'autoAlpha' = opacity almost; see docs https://greensock.com/docs/v3/GSAP/CorePlugins/CSSPlugin
//---------------------------------------------
const doStaggeredReveals = function() {
  gsap.set(textColumnSectionsToRevealWithStagger, {y: 20, autoAlpha: 0});
  ScrollTrigger.batch(textColumnSectionsToRevealWithStagger, {
    // interval: 0.1, // time window (in seconds) for batching to occur. 
    // batchMax: 3,   // maximum batch size (targets)
    onEnter: batch => gsap.to(batch, {
      autoAlpha: 1, 
      stagger: 0.3, 
      y:0
    }),
    start: "20px bottom",
    end: "top top"
  });
}


if (homepageBody && !prefersReducedMotionMediaQueryDetected) {
  doHomepageScrollReveals();
}

if (textColumnSectionsToRevealWithStagger.length > 0 && !prefersReducedMotionMediaQueryDetected) {
  // console.log(textColumnSectionsToRevealWithStagger);
  doStaggeredReveals();
}

if (!prefersReducedMotionMediaQueryDetected) {

  // Text to reveal on scroll trigger
  const toReveals = gsap.utils.toArray(textSectionsToReveal);

  toReveals.forEach(el => {
    gsap.from(el, {
      opacity: 0,
      y: '10%',
      ease: "power1.out", 
      duration: 0.75,
      scrollTrigger: {
        trigger: el,
        start: "top 80%"
      }
    });
  });

  //---------------------------------------------
  // Can't determine why the .newly-wrapped-content class isn't actually performing
  // ScrollTrigger reveals, but children of that element still animate properly.
  // For now, kill the ScrollReveals on the parents, reset their styles,
  //  and let the children animate only.
  //---------------------------------------------  
    // ScrollTrigger.getAll().forEach((triggerInstance) => {
    //   console.log(triggerInstance.trigger);
    //   // if (triggerInstance.trigger?.parentNode.classList.contains('newly-wrapped-content')) {
    //   //   console.log(`killing a newly wrapped content`);
    //   //   triggerInstance.kill();
    //   //   triggerInstance.trigger.style.opacity = '1';
    //   //   triggerInstance.trigger.style.transform = 'none';
    //   // }
    // })

  // Images scaling on scroll trigger, images fading in on scroll trigger
  const imgsToReveal = gsap.utils.toArray("img[loading='lazy']:not(.dont-animate)");
  imgsToReveal.forEach((img) => img.style.opacity = '0');
  imgsToReveal.forEach(img => {
    gsap.to(img, {
      opacity: 1,
      ease: "power1.out", 
      duration: .65,
      scrollTrigger: {
        trigger: img,
        start: "top 70%",
        once: true,
      }
    });
  });
  imgsToReveal.forEach(img => {
    gsap.to(img, {
      transform: 'scale(1.1)',
      ease: "power1.out", 
      duration: .85,
      scrollTrigger: {
        trigger: img,
        start: "top 65%",
        scrub: true,
        once: true,
      }
    });
  });

} else {
  sectionHeightCheck();
}


// ScrollTrigger.refresh();
