'use strict';

//---------------------------------------------
// *****************************************************
// ANCHOR NAV BAR SCROLLING OPTION:
//---------------------------------------------
// This will generate a list of navigation anchor links based on the page Bloqs created.
// It will grab the 'title' of the section - looks for an h2 tag - and mark that as the list
// item title to click on as the scroll button. If no title is found, the short_name of the 
// page bloq is utilized instead.
// The navigation will only condense once on first scroll, as opposed to growing/shrinking based
// on the scroll direction.
// The anchor nav bar will become sticky once the top of the anchor bar reaches the top of the screen,
// PLUS the height of the nav (which is defined as CONDENSED_NAV_HEIGHT in the config file)
// A layout variable is set based on a field's toggle status to determine whether or not this
// anchor bar will appear/be utilized or not.
// The generated anchor nav bar will be appended below the first section, which is 
// assumed to be the page's hero banner.
// *****************************************************
//---------------------------------------------

// Get the height of the condensed nav so that the anchor nav bar triggers and
// pins in the appropriate spot below it
import { CONDENSED_NAV_HEIGHT, TAILWIND_LG_BREAKPOINT, PATH_TO_SVGS_DETAILS, TAILWIND_MD_BREAKPOINT, prefersReducedMotionMediaQueryDetected } from "./config";
import { insertAfter } from "./utilities";

const lgBreakpoint = `(min-width: ${TAILWIND_LG_BREAKPOINT}px)`;
const mdBreakpointMAX = `(max-width: ${TAILWIND_LG_BREAKPOINT - 1}px)`;

// NOTE: All Bloqs Page Sections MUST INCLUDE the [data-block-name][data-block-parent="true"] section selector!!
// NOTE: We'll set a template variable of 'anchored page' to ensure that the functionality is turned on
const bloqSectionSelector = '[data-block-name][data-block-parent="true"]';

// Had to create .lg-position-initial in the custom-overrides because can't extend position property
// in tailwindcss
const anchorBarNavElClasses = ["bg-brand-navy", "z-[22]", 'shadow-md', 'transition-position', 
    'left-4', 'right-4', 
    'lg-position-initial', 'lg:left-0', 'lg:right-0', 'lg:shadow-none'];

const anchorBarUlStyles = `
    grid gap-4 text-center
    lg:flex items-center justify-evenly lg:gap-x-5 lg:flex-nowrap py-8 px-24
`;
const listItemStyles = ['text-white', "text-semibold", "font-base", "text-center"];
let anchorBarNavEl; // this has to be created before we can select it in the DOM

// MOBILE anchor bar button
const mobileToggleBtnStyles = ["rounded-full","bg-brand-navy","p-3","shadow-md",
    "fixed","bottom-5","left-5","z-[23]", "block", "lg:hidden"];
const iconArrowUp = `<img src="${PATH_TO_SVGS_DETAILS}icon-up-arrow-white.svg" alt=""/>`;
let mobileToggleBtn;


//---------------------------------------------
// Manual resetting of the positioning of the anchor nav bar element
// when we're either killing or recreating the ScrollTrigger;
// Height needs to be recalculated, and the bottom position has to be
// set for smooth transition on the mobile btn toggling
//---------------------------------------------
const resetAnchorNavBarStyles = function(screenSize) {
    if (screenSize === 'mobile') {
        anchorBarNavEl.style.position = 'fixed';
        anchorBarNavEl.style.bottom = `-${anchorBarNavEl.offsetHeight}px`;
    } else if (screenSize === 'desktop') {
        anchorBarNavEl.style.position = 'initial';
        anchorBarNavEl.style.bottom = 'initial';
    }
}

//---------------------------------------------
// Keep the anchor nav bar sticky at the top of the page
// once we reach it on scroll
// Initializes creation of ScrollTrigger object
//---------------------------------------------
const stickyifyAnchorBar = function() {
    // ** Not sure as to why this doesn't work.
    // ** the .matchMedia() should auto-kill the ScrollTriggers when
    // the media query doesn't apply, but doesn't seem to be working.
    // _________________________________
    // ScrollTrigger.matchMedia({
    //     "(min-width: 1024px)": function() {
    //         ScrollTrigger.create({
    //             trigger: anchorBarNavEl,
    //             start: `top top+=${CONDENSED_NAV_HEIGHT}`, // when the top of the nav bar reaches the top of the viewport + (condensed nav height)
    //             endTrigger: "html",
    //             end: "bottom bottom",
    //             pin: true,
    //             pinSpacing: false,
    //             scrub: 1,
    //         });
    //     }
    // })

    // NOTE: According to the docs, .matchMedia() should auto-kill STs when
    // the media query no longer applies, but doesn't seem to be working.
    // So manually killing off for now.
    ScrollTrigger.matchMedia({
        [mdBreakpointMAX]: () => {
            let scrollTriggers = ScrollTrigger.getAll();
            scrollTriggers.forEach((st) => {
                st.kill();
            });
            resetAnchorNavBarStyles('mobile');
        },
        [lgBreakpoint]: () => {
            resetAnchorNavBarStyles('desktop');
            gsap.timeline({
                scrollTrigger: {
                    trigger: anchorBarNavEl,
                    start: `top top+=${CONDENSED_NAV_HEIGHT}`, // when the top of the nav bar reaches the top of the viewport + (condensed nav height)
                    endTrigger: "html",
                    end: "bottom bottom",
                    pin: true,
                    pinSpacing: false,
                    scrub: 1,
                }
            })
        }
    });
}

//---------------------------------------------
// Given the corresponding location of the link, scroll the page to that spot
//---------------------------------------------
const navigateToSection = function(navLink) {
    // Heights of both of the nav bars - main nav and anchor bar nav
    let offsetHeightOfNavBars = anchorBarNavEl.offsetHeight + document.querySelector(`#topNavBar`).offsetHeight;
    let offset = offsetHeightOfNavBars; // The nav height + height of the anchor nav bar

    const sectionID = navLink.id;
    const toScrollTo = document.querySelector(`[data-block-id="${sectionID}"]`);

    // Don't need to 'pad' for the nav bar on mobile screens since it's hidden below viewport
    // *NOTE: This isn't perfect, since the navbar will condense from 100px to 60px (as of now)
    // when first scrolled down... so if the user immediately opens the menu without scrolling down first,
    // there will be a little white space for the 'scroll top' position (extra 40px)
    if (window.outerWidth <= (TAILWIND_LG_BREAKPOINT - 1)) {
        offset = document.querySelector(`#topNavBar`).offsetHeight;
    } 

    // Either scroll smoothly or jump to section based on prefers reduced motion/ not
    if (!prefersReducedMotionMediaQueryDetected) {
        window.scrollTo({
            behavior: 'smooth',
            top: toScrollTo.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
        });
    } else {
        window.scrollTo({
            behavior: 'auto',
            top: toScrollTo.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
        });
    }
}

//---------------------------------------------
// Adding event listeners for clicks on the anchor nav bar list items
//---------------------------------------------
const listenForAnchorBarClicks = function() {
    const anchorNavBarListEl = document.querySelector(`#anchor-nav-list`);

    anchorNavBarListEl.addEventListener('click', function(e) {
        if (e.target.tagName === 'A') {
            navigateToSection(e.target);
        }
    })
    
}

const listenForMobileAnchorBtnClicks = function() {
    mobileToggleBtn.addEventListener('click', function(e) {
        anchorBarNavEl.classList.toggle('open');
    })
}

//---------------------------------------------
// Create markup and document elements for the basis of the
// anchor bar nav, and append all of the appropriate nav links
// to the anchor bar based on the page's block/bloq sections
//---------------------------------------------
const createDesktopAndBaseAnchorBar = function() {
    // Generate what the HTML should look like the for anchor bar
    const anchorSections = document.querySelectorAll(bloqSectionSelector);
    const anchorBarInnerHTML = `
            <ul role="navigation" id="anchor-nav-list" class="${anchorBarUlStyles}">
            </ul>
    `;
    
    // Create a virtual DOM version of the anchor nav bar
    let anchorBarHTML = document.createElement('nav');
    anchorBarHTML.id = "anchor-bar-nav";
    anchorBarHTML.setAttribute('aria-label', "page section scrolling navigation");
    anchorBarHTML.classList.add(...anchorBarNavElClasses);
    anchorBarHTML.innerHTML = anchorBarInnerHTML;
    
    let anchorBarFragment = document.createDocumentFragment();
    anchorBarFragment.appendChild(anchorBarHTML);
    
    // Grab all of the page section bloqs and create navigable links to each
    anchorSections.forEach((bloqSection, i) => {
        // We'll index starting at 1 for simplicity (instead of zero 0 indexing)
        bloqSection.dataset.blockId = `anchor${i+1}`;
        // Allows section to be focusable via keyboard
        bloqSection.setAttribute('tabindex', '0');

        let listItem = document.createElement('li');
        let listItemLink = document.createElement('a');
        listItem.appendChild(listItemLink);
        listItemLink.classList.add(...listItemStyles);
        listItemLink.setAttribute('id', bloqSection.dataset.blockId);

        // Utilize the user's alternative title for the section
        // to populate on the anchor bar instead of the copy section's title
        // Otherwise, if the section has a heading, make that the anchor bar link to click
        // Else, just name it the type of block that it is as a fallback
        const listItemBlockName = bloqSection.dataset.blockName;
        const listItemAltTitle = bloqSection.dataset.altTitle;
        const listItemHeading = bloqSection.querySelector('h2')?.textContent;
        
        if (listItemAltTitle) {
            listItemLink.innerText = listItemAltTitle;
        } else if (listItemHeading) {
            listItemLink.innerText = listItemHeading;
        } else {
            listItemLink.innerText = listItemBlockName;
        }

        anchorBarFragment.querySelector('ul').appendChild(listItem);
    })
    
    // Once the anchor bar navigation is inserted into the DOM, we can select it
    insertAfter(anchorBarFragment, document.querySelector('section:first-of-type'));
    anchorBarNavEl = document.querySelector("#anchor-bar-nav");
}

//---------------------------------------------
// Generate the mobile toggle btn for the anchor bar nav menu
//---------------------------------------------
const createMobileAnchorBarOptions = function() {
    const mobileAnchorMenuBtn = document.createElement('button');
    mobileAnchorMenuBtn.classList.add(...mobileToggleBtnStyles);
    mobileAnchorMenuBtn.id="mobile-anchor-menu-toggle-btn";
    mobileAnchorMenuBtn.setAttribute('aria-label', 'Reveal scroll-to-section navigation menu');
    //`<button class= id="mobile-anchor-menu-toggle-btn"></button>`;
    let mobileToggleBtnFragment = document.createDocumentFragment();

    mobileToggleBtnFragment.appendChild(mobileAnchorMenuBtn);
    // Once the anchor bar navigation is inserted into the DOM, we can select it
    insertAfter(mobileToggleBtnFragment, document.querySelector('section:first-of-type'));

    mobileToggleBtn = document.querySelector('#mobile-anchor-menu-toggle-btn');
    mobileToggleBtn.insertAdjacentHTML('afterbegin', iconArrowUp);
}

//---------------------------------------------
// INITIALIZE ANCHOR BAR
//---------------------------------------------
const initAnchorBar = function() {

    createDesktopAndBaseAnchorBar();
    createMobileAnchorBarOptions();

    // Generates the ScrollTrigger
    stickyifyAnchorBar();

    // Adds event listeners for scroll-to positions
    listenForAnchorBarClicks();

    listenForMobileAnchorBtnClicks();

}


//---------------------------------------------
// *******************************************
// Where we initialize the anchor nav or not
// based on layout variable
//---------------------------------------------
export let isAnchoredPage = document.querySelector('body').classList.contains('anchored-page');
if (isAnchoredPage) {
    initAnchorBar();
}