'use strict';

import { urlPathArray } from "./utilities";

//---------------------------------------------
// On PDPs Product Detail pages:
// We are displaying the subcategories of products within the 
// current parent category associated with segment_1.
// The {categories} loop outputs all categories, so we must remove
// the non-relevant sub-categories from displaying.

// EX:
// "Our solution is to display icons for the ‘parent’ Industry categories, 
// so that users are able to see that the product can be utilized in other 
// industries. Then, in the slashed style, we have the current industry’s 
// sub-categories associated with that product being displayed. 
// So, since that page is the /marine URL for the product, it’s showing 
// the subcategories for Marine (so Lighting, Courtesy Lighting, 
// and Versicolor RGBW)"
//---------------------------------------------

const productCatBreadcrumbsParent = document.querySelector('#product-category-breadcrumbs-links');

const removeSlashDivider = function(breadcrumb) {
    const slashDivider = breadcrumb.nextElementSibling;
    if (slashDivider?.nodeName === "SPAN") {
        slashDivider.remove();
    }
}

if (productCatBreadcrumbsParent != null) {
    // Get the Product Industry that we're currently viewing
    const segment_1 = urlPathArray[1];

    // Check all breadcrumbs to see if they're a subcategory of the industry
    // that we're currently viewing. If not, remove it from the page.
    let breadcrumbs = productCatBreadcrumbsParent.querySelectorAll('a[data-cat-url-title]');
    breadcrumbs.forEach((breadcrumb) => {
        if (!breadcrumb.getAttribute('data-cat-url-title')?.includes(segment_1) || breadcrumb.getAttribute('is-parent') != null) {
           removeSlashDivider(breadcrumb);
           breadcrumb.remove();
        } 
    });

    // The breadcrumbs have changed from when we initially selected them in the DOM,
    // so we have to re-select them to get an updated array
    breadcrumbs = productCatBreadcrumbsParent.querySelectorAll('a[data-cat-url-title]');
    
    // Then we get rid of the slash after the last breadcrumb
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    removeSlashDivider(lastBreadcrumb);
}