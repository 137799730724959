'use strict';

export const TAILWIND_MD_BREAKPOINT = 768;
export const TAILWIND_LG_BREAKPOINT = 1024;
export const TAILWIND_XL_BREAKPOINT = 1280;

export const PATH_TO_SVGS_DETAILS = '/images/svgs-details/';

// Condensed height on page scrolling (in px) for the nav bar
// Used in scroll-to-anchor and navigation files
export const CONDENSED_NAV_HEIGHT = 60;

// See break-section-content-into-columns.js
export const SECTION_HEIGHT_LIMIT = 800;


//---------------------------------------------
// Account for the prefers-reduced-motion setting
//---------------------------------------------
// Grab the prefers reduced media query.
let prefersReducedMotionMediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
export let prefersReducedMotionMediaQueryDetected = prefersReducedMotionMediaQuery.matches || !prefersReducedMotionMediaQuery;

// TODO:
// Prefers reduced motion scroll to anchor DONE
// accesibility on forms DONE
// loading gif on mobile on homepage
// add controls for background video
// tabbing prefers reduced motion
// navigation prefers reduced
// scroll reveal of sections broken into two columns DONE


// Ads an event listener to check for changes in the media query's value.
prefersReducedMotionMediaQuery.addEventListener("change", () => {
  if (prefersReducedMotionMediaQuery.matches) {
    prefersReducedMotionMediaQueryDetected = true;
  } else {
    prefersReducedMotionMediaQueryDetected = false;
  }
});