import { Fancybox, Carousel } from "@fancyapps/ui";
// Panzoom also available *

// *******************************************
// NOTE: These commented Carousels are from the demo in components/fancyapps-slider-with-lightbox
// *******************************************
// if (document.querySelectorAll("#mainCarousel.carousel").length > 0) {
  // Initialise Carousel
  // const mainCarousel = new Carousel(document.querySelector("#mainCarousel"), {
  //   Dots: true,
  //   Navigation: true,
  //   slidesPerPage: 1,
  // });

  // Thumbnails
  // const thumbCarousel = new Carousel(document.querySelector("#thumbCarousel"), {
  //   Sync: {
  //     target: mainCarousel,
  //     friction: 0
  //   },
  //   Dots: false,
  //   Navigation: false,
  //   center: true,
  //   slidesPerPage: 1,
  //   infinite: false
  // });

  // Customize Fancybox
  // Fancybox.bind('[data-fancybox="gallery"]', {
  //   Carousel: {
  //     on: {
  //       change: that => {
  //         mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
  //           friction: 0
  //         });
  //       }
  //     }
  //   }
  // });
// } else 


// PDP hero slideshow
if (document.querySelectorAll("#productHeroCarousel").length > 0) {
    const productHeroCarousel = new Carousel(document.querySelector("#productHeroCarousel"), {
      Dots: true,
      Navigation: {
        prevTpl: `<svg class="stroke-transparent cursor-pointer z-10 focus:outline-brand outline-offset-4" data-slider-id="slider{entry_id}" xmlns="http://www.w3.org/2000/svg" width="17.2" height="25" viewBox="0 0 17.2 25"><path id="Previous_arrow" data-name="Previous arrow" d="M12.5,0,25,17.2H0Z" transform="translate(0 25) rotate(-90)" fill="#497e91"/></svg>`,
        nextTpl: `<svg class="stroke-transparent rotate-180 cursor-pointer z-10 focus:outline-brand outline-offset-4" data-slider-id="slider{entry_id}" xmlns="http://www.w3.org/2000/svg" width="17.2" height="25" viewBox="0 0 17.2 25"><path id="Previous_arrow" data-name="Previous arrow" d="M12.5,0,25,17.2H0Z" transform="translate(0 25) rotate(-90)" fill="#497e91"/></svg>`,
      },
      slidesPerPage: 1,
      infinite: false,
      fill: true,
    });

    Fancybox.bind(`[data-fancybox="gallery"]`, {
      Toolbar: {
        display: [
          { id: "prev", position: "center" },
          { id: "counter", position: "center" },
          { id: "next", position: "center" },
          "zoom",
          "slideshow",
          "fullscreen",
          "download",
          "thumbs",
          "close",
        ],
      },
      Carousel: {
        Navigation: {
          prevTpl: `<svg class="stroke-transparent cursor-pointer z-10 focus:outline-brand outline-offset-4" data-slider-id="slider{entry_id}" xmlns="http://www.w3.org/2000/svg" width="17.2" height="25" viewBox="0 0 17.2 25"><path id="Previous_arrow" data-name="Previous arrow" d="M12.5,0,25,17.2H0Z" transform="translate(0 25) rotate(-90)" fill="#497e91"/></svg>`,
          nextTpl: `<svg class="stroke-transparent rotate-180 cursor-pointer z-10 focus:outline-brand outline-offset-4" data-slider-id="slider{entry_id}" xmlns="http://www.w3.org/2000/svg" width="17.2" height="25" viewBox="0 0 17.2 25"><path id="Previous_arrow" data-name="Previous arrow" d="M12.5,0,25,17.2H0Z" transform="translate(0 25) rotate(-90)" fill="#497e91"/></svg>`,
        },
      }
    });
}
