"use strict";

import { TAILWIND_LG_BREAKPOINT } from "./config";
import Accordion from 'accordion-js';


// *************************************************
//---------------------------------------------
// NOTE: IMPORTANT: 
// I'v integrated the majority of accordions using pure vanilla JS as
// I was learning the capabilities and methods of basic JS.

// For sake of time and efficiency, I've added the npm package accordion-js
// since my initial integration wouldn't account for nested accordions.
//---------------------------------------------
// *************************************************

// ********TABLE OF CONTENTS********
// 1) MY PURE JS INTEGRATIONS
// 2) ACCORDION JS


// 1) MY PURE JS INTEGRATIONS
//---------------------------------------------
// Basic accordion functionality
//---------------------------------------------
const accordions = document.querySelectorAll(".accordion");

const adjustHeightForParentAccordions = function(accItem, accItemPanel) {
  // Get parent accordion, if it exists
  const parentAccordion = accItem.closest('.accordion');
  const topParentAccordionPanel = parentAccordion.closest('.accordion-collapse');

  if (accItemPanel.classList.contains("show")) {
    // Must add space to parent accordion for child accordion
    if (topParentAccordionPanel) {
      topParentAccordionPanel.style.height = parseInt(topParentAccordionPanel.style.height) + parseInt(accItemPanel.getAttribute("data-height")) + 'px';
    }
    accItemPanel.style.height = accItemPanel.getAttribute("data-height");
  } else {
    // Remove added space from parent accordion for child accordion
    if (topParentAccordionPanel) {
      topParentAccordionPanel.style.height = parseInt(topParentAccordionPanel.style.height) - parseInt(accItemPanel.getAttribute("data-height")) + 'px';
    }
    accItemPanel.style.height = 0;
  }
}

//   Get the 'open' height for each accordion panel, and return
//   the panel itself
const calcAndSetHeightsOfPanels = function (accItem) {
  const accItemPanel = accItem.querySelector(".accordion-collapse");
  const height = accItemPanel.scrollHeight;
  accItemPanel.setAttribute("data-height", height + "px");
  accItemPanel.style.height = 0;
  if (accItemPanel.classList.contains('show')) {
    adjustHeightForParentAccordions(accItem, accItemPanel);
  }
};

const listenForAccItemBtnClicks = function (accItem) {
  const accItemBtn = accItem.querySelector(".accordion-button");
  accItemBtn.addEventListener("click", function (e) {
    toggleHeight(accItem);
  }, {passive: true});
};

const initAccordions = function () {
  // Identify each button and panel & listen for click event
  accordions.forEach(function (acc) {
    const accItems = acc.querySelectorAll(":scope > .accordion-item");
    accItems.forEach(function (accItem) {
      calcAndSetHeightsOfPanels(accItem);
      listenForAccItemBtnClicks(accItem);
    });
  });
  openProductAdditionalInformationFilters();
};

//---------------------------------------------
// If we're on a Product Detail page, open the Additional Information accordion by default
//---------------------------------------------
const openProductAdditionalInformationFilters = function() {
  const productFiltersBtn = document.querySelector('[aria-controls="flush-collapseAdditionalInformation"]');
  if (productFiltersBtn) {
    setTimeout(function() {
      productFiltersBtn.click();
    }, 200);
  }
}

//---------------------------------------------
// Toggle the panel open/closed
//---------------------------------------------
const toggleHeight = function (accItem) {
  const accItemBtn = accItem.querySelector(".accordion-button");
  const accItemPanel = accItem.querySelector(".accordion-collapse");
  let isExpanded = accItemBtn.getAttribute("aria-expanded");
  isExpanded === "true"
    ? accItemBtn.setAttribute("aria-expanded", "false")
    : accItemBtn.setAttribute("aria-expanded", "true");

  accItemPanel.classList.toggle("collapse");
  accItemPanel.classList.toggle("show");

  adjustHeightForParentAccordions(accItem, accItemPanel);
};


//---------------------------------------------
// If the accordion we're watching is the Product filtering accordion
// on a Market page, make sure it's opened when on Desktop (lg and up)
//---------------------------------------------
const expandProductFiltersAtLarge = function(productFilters) {
  let productFiltersBtn = productFilters.querySelector('button');
  // console.log(productFiltersBtn);
  if (window.outerWidth > TAILWIND_LG_BREAKPOINT) {
    // NOTE: The attribute value is a string of false, not a boolean of false!**
    if (productFiltersBtn.getAttribute('aria-expanded') == 'false') {
      productFiltersBtn.click();
      // toggleHeight(productFilters);   
    }
    productFiltersBtn.disabled = true; 
  } else {
    productFiltersBtn.disabled = false;
  }
}

window.addEventListener("load", function () {
  // const productSearchFilters = document.querySelector("#product-search-filters");

  if (accordions.length > 0) {
    let prevWidth = 0;

    // on Market pages - Product filtering options
    const productFilters = document.querySelector('.product-search-wrapper');
    if (productFilters) {
      expandProductFiltersAtLarge(productFilters);
    }

    // Utilizing ResizeObserver for changes on the body width instead
    // of listening on window.onresize() because of performance benefit
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === "number" && width !== prevWidth) {
          prevWidth = width;
          // 1. For the MANUALLY CREATED ACCORDIONS
          const accItems = entry.target.querySelectorAll(".accordion-item");
          accItems.forEach(function (accItem) {
            // this call will set the accordions back to zero height
            calcAndSetHeightsOfPanels(accItem);
            // resetDropdownStates(accItem);


            // **************
            // IMPORTANT!!!
            // NOTE: No longer needed, as product filters are using different
            // accordionjs NPM version because manual accs were broken
            // SO: expandProductFiltersAtLarge() = obsolete in this instance
            // **************

            // Need to make sure filters are always displayed and accordion
            // is disabled on desktops for Product filtering on Market pages
            // if (productFilters === accItem) {
            //   expandProductFiltersAtLarge(productFilters);
            // }
          });
        }
      }
    });

    resizeObserver.observe(document.querySelector("body"), { box: "border-box" });

    initAccordions();

  }
}, {passive:true});

// For use in form-submit-with-ajax.js:
// We have to recalculate the height attribute for the panels
// since errors/success messages of the forms within an accordion will
// cause the height of the panel to grow
export { calcAndSetHeightsOfPanels };


// 2) ACCORDION JS
// NOTE: This is only currently in use for Industry page accordion filters;
// NOTE: As of 11/2022, being used for Navigation menu accordion
// See above (1) for accordion elements not related to accordion-js npm setup!
if (document.querySelectorAll('.accordion-container').length > 0) {
  
  const accordions = Array.from(document.querySelectorAll('.accordion-container'));
  accordions.forEach((accordionEl) => {
    const accInitiated = new Accordion(accordionEl, {
      duration: 400,
      showMultiple: true,
    });

    // If the accordion is the Industry page's product search filter categories, open upon init
    if (accordionEl.classList.contains('product-search-filters-and-results')) {
      const accID = accordionEl.querySelector('.ac').id.replace('ac-', '');
      accInitiated.open(accID);
    } else if (accordionEl.classList.contains('resource-search-filters-and-results')) {
      const accID = accordionEl.querySelector('.ac').id.replace('ac-', '');
      accInitiated.open(accID);
    }
  });


}

