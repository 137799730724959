"use strict";

// IMPORTANT:NOTE: The navigation accordions no longer use the manual
// integration for accordions. It is now using accordion-js npm package
// as of 11-2022. 

// We're preventing the expansion of the navbar if there's also an anchor bar on the page
import { isAnchoredPage } from "./scroll-to-anchor";
import {CONDENSED_NAV_HEIGHT} from "./config";

const body = document.querySelector('body');
const nav = document.querySelector('nav');
const topNavBar = nav.querySelector('#topNavBar');
const navMenu = nav.querySelector('#nav-menu');
const navAccordions = navMenu.querySelectorAll(".isParentNavItem");
const toggleNavBtn = topNavBar.querySelector("#toggle-nav-btn");
const toggleSearchBtn = topNavBar.querySelector('#toggle-search-btn');
const searchForm = topNavBar.querySelector('#search-form');
const mainContent = document.querySelector('[role="main"]');

// This allows us to condense the nav bar on first scroll, then prevent it 
// from 'playing' again on anchor nav bar pages
let condensed = false;

// Helps us to 'lock' the window's scrolled position when toggling the fixed overlay
// nav menu; otherwise, it'll shoot the user back to the top of the page every time
let topScrollPosn;

//---------------------------------------------
// Nav scrolling animation - hides on scroll down,
// reveal on scroll up
// ** Preventing this behavior (to expand back to full height) on pages with an anchored nav bar **
//---------------------------------------------
  const navScrollAnim = gsap.from(topNavBar, { 
    height: CONDENSED_NAV_HEIGHT,
    paused: true,
    duration: 0.1,
  }).progress(1);
  
  ScrollTrigger.create({
    start: "top top",
    end: 99999,
    onUpdate: (self) => {
        //   When scrolling up, reveal the full height of nav.
        //   Otherwise, condense the height while scrolling down.
        //  ** as long as we're not on an anchor bar page - keep it condensed if we are
        if (self.direction === -1) {
          if (!isAnchoredPage) {
              navScrollAnim.play();
              topNavBar.classList.add('fullyOpen');
            } else if (isAnchoredPage && !condensed) {
              // Let the nav bar condense on the first scroll, then
              // don't play (reverse) the condense/grow animation again
              navScrollAnim.reverse();
              topNavBar.classList.remove('fullyOpen');
              condensed = true;
          }
        } else {
          navScrollAnim.reverse();
          topNavBar.classList.remove('fullyOpen');
        }
    }
  });


//---------------------------------------------
// Toggle open parent navigation items in menu
// ----
// NOTE: This is using same basic structure for
//       behavior as components/accordion.js, but
//       modified selectors and adjustments of
//       parent elements, etc.
//---------------------------------------------
const initToggleNavAccordions = function() {
  if (navAccordions.length > 0) {
    //   Toggle the panel open/closed
    const toggleHeight = function (acc) {
      const accItemBtn = acc.querySelector("button[role='button']");
      const accItemPanel = acc.querySelector(".sub-navigation");

      // Toggle aria attributes and classes for button, sub-nav list
      let isExpanded = accItemBtn.getAttribute("aria-expanded");
      console.log(isExpanded);
      isExpanded === "true"
        ? accItemPanel.setAttribute("aria-expanded", "false")
        : accItemPanel.setAttribute("aria-expanded", "true");
      accItemPanel.classList.toggle("collapse");
      accItemPanel.classList.toggle("open");
      acc.classList.toggle('open');
      acc.classList.toggle('collapse');
      if (accItemPanel.classList.contains("open")) {
        console.log(accItemPanel.getAttribute("data-height"));
        accItemPanel.style.height = accItemPanel.getAttribute("data-height");
      } else {
        accItemPanel.style.height = 0;
      }
    };

    //---------------------------------------------
    // When screen is resized, reset all classes and attributes associated with
    // the open/active states so as to not confuse users
    //---------------------------------------------
    const resetNavDropdownStates = function (acc) {
      const accItemPanel = acc.querySelector(".sub-navigation");
      const accItemBtn = acc.querySelector("button[role='button']");
      accItemBtn.setAttribute('aria-expanded', 'false');
      const btnListItem = accItemBtn.parentElement;
      btnListItem.classList.remove('open', 'collapse');
      accItemPanel.setAttribute('aria-expanded', 'false');
      accItemPanel.classList.remove('open', 'collapse');
    }

    //   Get the 'open' height for each accordion panel, and return
    //   the panel itself
    const calcAndSetHeightsOfNavDropdowns = function (acc) {
      const accItemPanel = acc.querySelector(".sub-navigation");
      const height = accItemPanel.scrollHeight;
      accItemPanel.setAttribute("data-height", height + "px");
      accItemPanel.style.height = 0;
    };

    const listenForNavAccItemBtnClicks = function (acc) {
      const accItemBtn = acc.querySelector("button[role='button']");
      accItemBtn.addEventListener("click", function () {
        toggleHeight(acc);
      }, {passive: true});
    };

    const initNavAccordions = function () {
      // Identify each button and panel & listen for click event
      navAccordions.forEach(function (acc) {
        // calcAndSetHeightsOfNavDropdowns(acc);
        // listenForNavAccItemBtnClicks(acc);
      });
    };

    let prevWidth = 0;

    // Utilizing ResizeObserver for changes on the body width instead
    // of listening on window.onresize() because of performance benefit
    // const resizeObserver = new ResizeObserver(entries => {
    //   for (let entry of entries) {
    //     const width = entry.borderBoxSize?.[0].inlineSize;
    //     if (typeof width === "number" && width !== prevWidth) {
    //       prevWidth = width;
    //       const accItems = entry.target.querySelectorAll(".isParentNavItem");
    //       accItems.forEach(function (acc) {
    //         resetNavDropdownStates(acc);
    //         calcAndSetHeightsOfNavDropdowns(acc);
    //       });
    //     }
    //   }
    // });

    // resizeObserver.observe(document.querySelector("body"), { box: "border-box" });

    // initNavAccordions();
  }

//---------------------------------------------
// When the overlay menu opens, need to maintain scroll position
// without jumping to the top of the viewport (because of position:fixed)
// being set
// NOTE: The execution order within the conditionals matters:
// "For the scrollTo() method to work, the document must be larger than the screen, and the scrollbar must be visible."
//---------------------------------------------
function maintainScrolledScreenPosition() {
  if (!body.classList.contains('noscroll')) {
    topScrollPosn = window.scrollY;
    body.style.top = `${-topScrollPosn}px`;
    body.classList.toggle('noscroll');
  } else {
    body.classList.toggle('noscroll');
    window.scrollTo(0, topScrollPosn);
    body.style.top = '';
  }
}

//---------------------------------------------
// Toggle the blur of the div.main content when the
// nav menu is opened/closed; slide the menu
// out from the right side
//---------------------------------------------
  const toggleNavMenu = function() {
    toggleNavBtn.classList.toggle("open");
    nav.classList.toggle("open");
    navMenu.classList.toggle('open');

    if (toggleNavBtn.getAttribute('aria-expanded') == 'false') {
      toggleNavBtn.setAttribute('aria-expanded', 'true');
      toggleNavBtn.setAttribute('aria-hidden', 'false');
    } else {
      toggleNavBtn.setAttribute('aria-expanded', 'false');
      toggleNavBtn.setAttribute('aria-hidden', 'true');
    }
      
    
    mainContent.classList.toggle('blurred');
    if (mainContent.getAttribute('aria-hidden') == 'true') {
      mainContent.setAttribute('aria-hidden', 'false'); 
    } else {
      mainContent.setAttribute('aria-hidden', 'true'); 
    }

    maintainScrolledScreenPosition();
  }

  //---------------------------------------------
  // Open nav drawer on button click
  //---------------------------------------------
  toggleNavBtn.addEventListener("click", function () {
      toggleNavMenu();
    }, {passive: true});

}


window.addEventListener("load", function () {
  // Wait for DOM to be loaded before getting the height of each
  // sub-nav el's actual height
  initToggleNavAccordions();
}, {passive: true});

//---------------------------------------------
// Toggling the display of the easyAutocomplete search form
//---------------------------------------------
toggleSearchBtn.addEventListener("click", function() {
  searchForm.classList.toggle('revealed');
  toggleSearchBtn.classList.toggle('revealed');

  toggleSearchBtn.toggleAttribute('aria-expanded', true);
  toggleSearchBtn.toggleAttribute('aria-hidden', true);
});