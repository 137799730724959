'use strict';

// import { SECTION_HEIGHT_LIMIT } from "./config";

//---------------------------------------------
// Checks to see if the copy area for an Image with Copy section is super tall.
// If so, take all of the copy and break it into two columns.
// INTENT - For Image with Copy sections; so specifically, for the design
// of the Capabilities page that has a LOT of listed bulleted content

// NOTE: for now, we're only breaking up with a toggle option. Otherwise,
// it's not changing dynamically based on detected height of content.
//---------------------------------------------
const sectionHeightCheck = function() {

    // ***********************************************************************************************
    // **** NOTE: This is only impacting Image With Copy sections for the time being!*********
    // ***********************************************************************************************
    const imageWithCopyCopySections = document.querySelectorAll('[data-block-name*="image_with_copy_copy"]');

    if (imageWithCopyCopySections) {

        imageWithCopyCopySections.forEach((section) => {
            // If the content is too tall, ... removed for now
            // (section.scrollHeight > SECTION_HEIGHT_LIMIT) || 
            
            // If the user prefers to have copy contnent broken into two columns, 
                if (section.getAttribute('data-break-into-columns')) {

                    // Create a new wrapper element to split content into columns
                    const wrapper = document.createElement('div');
                    wrapper.classList.add('block', 'flow', 'newly-wrapped-content');
                    wrapper.style.opacity = '1';
                    wrapper.style.transform = 'translate(0,0)';

                    // Set the content from the section as the inner content
                    // to append
                    // ** This is the only way that I know how to do so in order
                    // to maintain the DOM hierarchy...
                    wrapper.innerHTML = section.innerHTML;

                    // Grab the heading from the section separately
                    const sectionHeading = section.querySelector('h2');

                    // Clear the parent section area
                    section.innerHTML = '';

                    // Append the new wrapped content, and the existing heading,
                    //  in a wrapper to the copy area
                    section.appendChild(sectionHeading);
                    section.appendChild(wrapper);

                    // Remove the original heading from the newly wrapped
                    // and inserted content
                    const originalHeading = wrapper.children[0];
                    if (originalHeading.nodeName.toLowerCase() == 'h2') {
                        originalHeading.remove();
                    }

                    // Reapply the detail line to the new heading
                    section.querySelector('h2').classList.add('append-new-accent-here');

                    // Get all of the children from within the newly-wrapped-content
                    // and the grandchildren, too
                    // Then just turn off the ScrollTrigger animations for these elements
                    [...wrapper.children].forEach((childEl) => {
                        childEl.style.transform = 'none';
                        childEl.style.opacity = '1';
                        [...childEl.children]?.forEach((childsChildEl) => {
                            childsChildEl.style.transform = 'none';
                            childsChildEl.style.opacity = '1';
                        })
                    })
                }
        });
    }
}

sectionHeightCheck();
