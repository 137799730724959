import smoothscroll from "smoothscroll-polyfill";
import '../node_modules/lite-youtube-embed/src/lite-yt-embed.js';
import '@slightlyoff/lite-vimeo';
// import Accordion from 'accordion-js'; ** Imported in accordions.js file

// Base level imports

// IMPORT CONSTANT variables
import './components/config.js'; 

// import "./components/tabs.js";
// import "./components/modal.js";

import "./components/utilities.js"; 
import "./components/cookie.js"; // for Notice message option  

// ****************************************
// Modified from base level 'imports'

import "./components/contact-form-submit-with-ajax.js"; 
import "./components/navigation.js";
import "./components/tiny-sliders.js";
import "./components/fancyapps-carousels-with-lightbox.js";
import "./components/accordions.js";
import "./components/autocomplete-search.js";

import "./components/scroll-animations.js";

import "./components/forms.js";
import "./components/find-a-rep-filters.js";
import "./components/scroll-to-anchor.js";
import "./components/break-section-content-into-columns.js";
import "./components/scroll-to-results-if-filtered.js";
import "./components/product-subcategories-display-control.js"
import "./components/see-all-products_industry-page.js"


import "./styles/main.scss";

smoothscroll.polyfill();

// gsap.registerPlugin(ScrollTriggerPlugin);

