"use strict";

// import { calcAndSetHeightsOfPanels } from './accordions';

const newsResultsForm = document.querySelector('#news-category-filter-form');
const contactForm = document.querySelector('#contact-form');


//---------------------------------------------
// News page:
// There is no 'submit' button for the News filtering, so submit
// the filtering form every time the filter changes
//---------------------------------------------
if (newsResultsForm) {
    const newsCategorySelect = newsResultsForm.querySelector('select');

    newsCategorySelect.addEventListener('change', function() {
        newsResultsForm.submit();
    })
}

//---------------------------------------------
// Contact page:
// If the selected dropdown option is either 'Referral'
// or 'Other', reveal the hidden input on the form
//---------------------------------------------
if (contactForm) {
    const howDidYouFindUsSelect = contactForm.querySelector('#form-input-how_did_you_find_us');
    const howDidYouFindUsInput = contactForm.querySelector('#form-input-please_specify');
    const accItem = contactForm.parentElement.parentElement.parentElement;

    howDidYouFindUsInput.classList.add('!hidden');
    howDidYouFindUsSelect.addEventListener('change', function() {
        if (howDidYouFindUsSelect.value == "Referral" || howDidYouFindUsSelect.value == "Other") {
            toggleHiddenInputOnForm(accItem, true);
        } else {
            toggleHiddenInputOnForm(accItem, false);
        }
    });

    // Show/hide the option to add the 'who' for the selected source
    const toggleHiddenInputOnForm = function(accItem, openTheInput) {
        if (openTheInput) {
            howDidYouFindUsInput.classList.remove('!hidden');
        } else {
            howDidYouFindUsInput.classList.add('!hidden');
        }
        // calcAndSetHeightsOfPanels(accItem);
    }

    const updateLabelForRequiredFields = function() {
        const requiredFieldLabels = document.querySelectorAll('.freeform-required');
        requiredFieldLabels.forEach((label) => {
            let labelText = label.textContent;
            // console.log(labelText);
            label.textContent = `${labelText} (required)`;
        })
    }

    updateLabelForRequiredFields();
}

