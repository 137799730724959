'use strict';

//---------------------------------------------
// ON the Industry pages, we're showing the first 15 results.
// When users click the 'See all' button, the rest of the results
// (the second .product-search-results-grid) is revealed
//---------------------------------------------

const seeAllProductsBtn = document.querySelector('#reveal-all-products');
const moreProductResultsSection = document.querySelector('.more-results_hidden');

if (seeAllProductsBtn && moreProductResultsSection) {
    seeAllProductsBtn.addEventListener('click', function() {
        seeAllProductsBtn.classList.add('!hidden');
        moreProductResultsSection.classList.remove('more-results_hidden');
        moreProductResultsSection.classList.add('!mt-7'); // This matches the 'gap' on the product results to make
        // the secondary grid flow seamlessly
    })
}